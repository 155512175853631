export default class PostalAddressValidator {
  constructor(formElement) {
    this.form = formElement;
    this.postalAddressElement = this.form.find('[name="user[postal_address]"]').first();
    this.streetNameElement = this.form.find('[name="user[address]"]').first();
    this.zipCodeElement = this.form.find('[name="user[zip_code]"]').first();
    this.countryElement = this.form.find('[name="user[country_id]"]').first();

    if (
      this.postalAddressElement.length === 0 ||
      this.countryElement.length === 0 ||
      this.streetNameElement.length === 0
    ) {
      return;
    }

    this.form.formValidation('addField', this.postalAddressElement, {
      validators: {
        remote: {
          message: Hillary.invalidPostalAddressMessage,
          url: '/swisspost/address_validations/validate',
          method: 'GET',
          async: true,
          data: () => ({
            locale: Hillary.locale,
            street_name: this.streetNameElement.val(),
            zip_code: this.zipCodeElement.val(),
          }),
        },
      },
    });

    this.onCountryChanged();
    this.countryElement.on('change', _e => this.onCountryChanged());
    this.streetNameElement.on('change', _e => this.onPostalAddressChanged());
    this.zipCodeElement.on('change', _e => this.onPostalAddressChanged());
  }

  onPostalAddressChanged() {
    const concatenated = `${this.streetNameElement.val()},${this.zipCodeElement.val()}`;
    this.postalAddressElement.val(concatenated);
    this.form.formValidation('revalidateField', 'user[postal_address]');
  }

  onCountryChanged() {
    const swissAddressSelected = this.countryElement.find('option:selected').first().data('default');

    if (swissAddressSelected) {
      this.form.formValidation('enableFieldValidators', 'user[postal_address]', true, 'remote');
    } else {
      this.form.formValidation('enableFieldValidators', 'user[postal_address]', false, 'remote');
    }
  }
}
