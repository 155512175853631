export default class PasswordSwitcher {
  constructor() {
    $('[data-toggle-password]').on('click', function (evt) {
      const target = $(`#${$(this).data('togglePassword')}`);
      const icon = $(`[data-toggle-password-icon='${$(this).data('togglePassword')}']`);
      if (target.attr('type') === 'text') {
        target.attr('type', 'password');
        icon.removeClass('fa-eye-slash');
        icon.addClass('fa-eye');
      } else {
        target.attr('type', 'text');
        icon.addClass('fa-eye-slash');
        icon.removeClass('fa-eye');
      }
    });
  }
}
