import VisibilitySwitcher from '../common/visibility_switcher';
import PreValidationScreen from './pre_validation_screen';
import PostValidations from './post_validations';
import TwoFactorConfirmations from './two_factor_confirmations';
import './number_verifications';
import PasswordSwitcher from '../common/password_switcher';
import PreviousAddressSwitcher from './previous_address_switcher';
import FormTypeSelector from './form_type_selector';
import SecondCardSelector from './second_card_selector';
import ApplicationFormValidator from './application_form_validator';
import QuoteSanitizer from './quote_sanitizer';
import DocumentSelector from './document_selector';
import LiablePersonSwitcher from './liable_person_switcher';
import MobileNumberTrimmer from './mobile_number_trimmer';
import PromotionFields from './promotion_fields';

class FormManager {
  constructor() {
    new VisibilitySwitcher($('#user_no_street_number'), $('[data-street-number]'));
    new VisibilitySwitcher($('#user_no_street_number_previous'), $('[data-street-number-previous]'));
    for (let i = 0; i < Hillary.liablePeopleCount; i += 1) {
      new VisibilitySwitcher(
        $(`#user_liable_people_attributes_${i}_no_street_number`),
        $(`[data-liable-people-${i}-street-number]`),
      );
    }
    new PasswordSwitcher();
    new PreviousAddressSwitcher();
    new FormTypeSelector();
    new SecondCardSelector();
    new PromotionFields();
    new MobileNumberTrimmer();

    if ($('[data-controller="identity-upload"]').length === 0) {
      new ApplicationFormValidator();
    }

    new QuoteSanitizer();
    new DocumentSelector();
    new LiablePersonSwitcher();
  }
}

window.HillaryUsersCompleted = class HillaryUserCompleted {
  constructor() {
    new PasswordSwitcher();
    new MobileNumberTrimmer();
  }
};

window.HillaryUsersNew = class HillaryUsersNew {
  constructor() {
    new FormManager();
    new PreValidationScreen();
  }
};


window.HillaryUsersEdit = class HillaryUsersEdit {
  constructor() {
    new FormManager();
  }
};

window.HillaryUsersUpdate = class HillaryUsersUpdate {
  constructor() {
    new FormManager();
  }
};


window.HillaryUsersCreate = class HillaryUsersCreate {
  constructor() {
    if ($('form').length) {
      new FormManager();
      $('[data-toggle="popover"]').popover({ trigger: 'focus' });
      $('form').formValidation('validateField', 'user[pin_code]');
    }
  }
};

window.HillaryPinSelectionsNew = class HillaryPinSelectionsNew {
  constructor() {
    new PasswordSwitcher();
  }
};

window.HillaryMobileScansUpload = class HillaryMobileScansUpload {
  constructor() {
    new DocumentSelector();
  }
};

window.HillaryMobileScansUpdate = class HillaryMobileScansUpdate {
  constructor() {
    new DocumentSelector();
  }
};

window.HillaryRegistrationsShow = class HillaryRegistrationsShow {
  constructor() {
    if (this.isMobileSafari()) {
      this.launchAppleApp();
    }
    if (this.isMobileAndroid()) {
      this.launchAndroidApp();
    }
  }

  isMobileSafari() {
    return navigator.userAgent.match(/(iPod|iPhone|iPad)/);
  }

  isMobileAndroid() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1;
  }

  launchAppleApp() {
    return setTimeout(() => window.open(`manorcard://registrationcode/${window.hillaryCode}`, '_self'), 5000);
  }

  launchAndroidApp() {
    return setTimeout(() => {
      const packageName = 'package=com.accarda.mpa.manor;end';
      window.open(`intent://registrationcode/${window.hillaryCode}#Intent;scheme=manorcard;${packageName}`, '_self');
    }, 5000);
  }
};
