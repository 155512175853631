window.CookiesChecker = class CookiesChecker {
  constructor() {
    const cookiesEnabled = this.areCookiesEnabled();
    if (!cookiesEnabled) {
      this.show422message();
    }
  }

  areCookiesEnabled() {
    let { cookieEnabled } = navigator;
    if ((typeof navigator.cookieEnabled === 'undefined') && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
    }
    return cookieEnabled;
  }

  show422message() {
    $('form').hide();
    $('[data-unprocessable-entity]').show();
  }
};
