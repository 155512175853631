import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;

export default class IdentityDropzone {
  constructor(dropzoneElement) {
    this.dropzoneElement = dropzoneElement;
  }

  setup(uploadSuccessCallback, uploadErrorCallback) {
    if (this.dropzoneElement.length === 0) {
      return false;
    }

    const url = this.dropzoneElement.data('url');
    const name = this.dropzoneElement.data('name');
    const uploadedImageUrl = this.dropzoneElement.data('uploaded-file');
    const uploadedImageFilename = this.dropzoneElement.data('uploaded-filename');

    this._setupDropzone(url, name, uploadSuccessCallback, uploadErrorCallback, uploadedImageUrl, uploadedImageFilename);

    return true;
  }

  _setupDropzone(
    url, name, uploadSuccessCallback, uploadErrorCallback,
    uploadedImageUrl = undefined, uploadedImageFilename = undefined,
  ) {
    const klassRef = this;

    this.dropzoneElement.dropzone({
      url,
      method: 'put',
      paramName: `user[${name}]`,
      uploadMultiple: false,
      parallelUploads: 1,
      acceptedFiles: 'image/*',
      capture: 'environment',
      dictInvalidFileType: Hillary.invalidIdentityFileType,
      init() {
        this.on('success', (file, _response) => uploadSuccessCallback($(this.element), file));
        this.on('error', (file, errorMessage) => uploadErrorCallback($(this.element), errorMessage));
        klassRef._registerAdditionalDropzoneData.call(klassRef, this);

        if (uploadedImageUrl) {
          const image = {
            accepted: true,
            name: uploadedImageFilename || 'Image',
            size: 12345,
          };

          this.displayExistingFile(image, uploadedImageUrl);
          this.files.push(image);
        }
      },
    });
  }

  _registerAdditionalDropzoneData(dropzone) {
    const additionalParams = this.dropzoneElement.data('additional');

    dropzone.on('sending', (file, xhr, formData) => {
      dropzone.removeAllFiles();

      formData.append('authenticity_token', this._fetchAuthenticityToken());
      formData.append('format', 'js');
      formData.append('user[id_scan_type]', $(this.dropzoneElement).attr('data-id-type'));

      Object.entries(additionalParams).forEach(([param, data]) => {
        formData.append(`user[${param}]`, data);
      });
    });
  }

  _fetchAuthenticityToken() {
    const meta = document.querySelector('meta[name="csrf-token"]');
    return meta && meta.content;
  }
}
