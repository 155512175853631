import { Controller } from 'stimulus';

export default class CardsController extends Controller {
  static get targets() {
    return ['content'];
  }

  toggle(e) {
    const isHidden = !e.target.checked;

    if (isHidden) {
      this._clearChildValues();
    }

    $(this.contentTarget).toggleClass('d-none', isHidden);
  }

  _clearChildValues() {
    const $inputs = $(this.contentTarget).find('input');
    if ($inputs.length === 0) {
      return;
    }

    $inputs.val('');

    const fv = $($inputs[0].form).data('formValidation');
    $inputs.each((_index, element) => {
      if ('KeyboardEvent' in window) {
        element.dispatchEvent(new KeyboardEvent('keyup'));
      }

      return fv.validateField($(element).attr('name'));
    });
  }
}
