export default class FormTypeSelector {
  constructor() {
    this.selector = $('[name="card_type_switcher"]');
    this.fullFields = $('[data-full-fields]');
    this.standardFields = $('[data-standard-fields]');
    this.processType = $('[name="user[process_type]"]');
    if (this.selector.length) {
      this.selector.on('click', () => this.changeFieldsVisibility());
      this.changeFieldsVisibility();
    }
  }
  changeFieldsVisibility() {
    const val = this.selector.is(':checked');
    if (val) {
      this.processType.val(Hillary.processTypes.kkg);
    } else {
      this.processType.val(Hillary.processTypes.nonKkg);
    }
    this.fullFields.toggle(val);
    this.standardFields.toggle(!val);
  }
}
