import { Controller } from 'stimulus';
import CardPreviewController from '../../registrations/controllers/card_preview_controller';

export default class extends CardPreviewController {
  static get targets() {
    return ['line1', 'line2', 'line3', 'deliveryAddress', 'account'];
  }

  connect() {
    this._showCurrentAddress(this.accountTarget.value);
  }

  accountChanged(e) {
    const nameWithNumber = e.target.options[e.target.selectedIndex].innerHTML;
    const name = nameWithNumber.split(' ').slice(1).join(' ');
    $(this.line1Target).html(name);
    this._showCurrentAddress(e.target.value);
  }

  _showCurrentAddress(currentAccount) {
    const deliveryAddress = this.deliveryAddressTargets
      .find(address => address.getAttribute('data-account-id') === currentAccount);
    this.deliveryAddressTargets.forEach(address => address.classList.add('d-none'));
    deliveryAddress.classList.remove('d-none');
  }
}
