export default class DateValidator {
  constructor() {
    $('[data-provide="datepicker"]').each((index, el) => {
      const fieldName = el.name;
      const field = $(el);
      field.datepicker().on('changeDate', e => $('form').formValidation('revalidateField', fieldName));
      field.datepicker().on('hide', e => $('form').formValidation('revalidateField', fieldName));
    });
  }
}
