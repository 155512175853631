export default class LabelsConverter {
  attach() {
    $('[data-radiobutton]').on('click', function () {
      return $(this).find('input').prop('checked', true).change();
    });

    $('input').on('input', e => this.switchLabel(e.currentTarget));

    $('select').on('change', e => this.switchLabel(e.currentTarget));

    $('input, select').each((idx, el) => this.switchLabel(el));

    if ($.fn.datepicker) {
      $('[data-provide="datepicker"]').datepicker().on('changeDate', e => this.switchLabel(e.currentTarget));

      $('[data-provide="datepicker"]').datepicker().on('hide', e => this.switchLabel(e.currentTarget));

      $('[data-provide="datepicker"]').datepicker().on('show', (e) => {
        const field = $(this).closest('.form-group');
        return field.addClass('form-group--not-empty');
      });
    }
  }

  switchLabel(element) {
    const field = $(element).closest('.form-group');
    if (($.trim(element.value) !== '') || ($(element).is('select') && ($(element).find(':selected').text() !== ''))) {
      return field.addClass('form-group--not-empty');
    }
    return field.removeClass('form-group--not-empty');
  }
}
