import '../public/codepage850';

const recipientValidationCallback = (form) => {
  const elements = document.querySelectorAll('[data-recipients-information]');
  const emptyFields = [...elements].filter(element => element.value === '');
  const fv = form.data('formValidation');
  if (emptyFields.length === elements.length) {
    elements.forEach(field => fv.updateStatus($(field), 'INVALID', 'callback'));
    return false;
  }
  elements.forEach(field => fv.updateStatus($(field), 'VALID', 'callback'));
  return true;
};

const validateLimitPresence = (form) => {
  const fv = form.data('formValidation');
  const upperLimitElement = $('[data-notifications-target="upperVolumeLimit"]');
  const lowerLimitElement = $('[data-notifications-target="lowerVolumeLimit"]');
  if (upperLimitElement.val() === '' && lowerLimitElement.val() === '') {
    fv.updateMessage(lowerLimitElement, 'callback', Hillary.notificationsValidationLimitsBlank);
    fv.updateMessage(upperLimitElement, 'callback', Hillary.notificationsValidationLimitsBlank);
    fv.updateStatus(upperLimitElement, 'INVALID', 'callback');
    fv.updateStatus(lowerLimitElement, 'INVALID', 'callback');
    fv.validate();
    return false;
  }
  return true;
};

const validateLimits = (form) => {
  const fv = form.data('formValidation');
  const upperLimitElement = $('[data-notifications-target="upperVolumeLimit"]');
  const lowerLimitElement = $('[data-notifications-target="lowerVolumeLimit"]');
  const upperLimit = parseFloat(upperLimitElement.val());
  const lowerLimit = parseFloat(lowerLimitElement.val());
  fv.updateMessage(lowerLimitElement, 'callback', null);
  fv.updateMessage(upperLimitElement, 'callback', Hillary.volumeUpperLimitError);
  const result = !lowerLimit || !upperLimit || lowerLimit <= upperLimit;
  fv.updateStatus(lowerLimitElement, 'VALID', 'callback');
  const status = result ? 'VALID' : 'INVALID';
  fv.updateStatus(upperLimitElement, status, 'callback');
  fv.validate();
  return result;
};

export default class NotificationValidator {
  constructor() {
    const _form = $('[data-notification-form]');
    if (!_form.length) {
      return;
    }
    _form.formValidation({
      locale: Hillary.shortLocale,
      trigger: 'blur',
      fields: {
        'notification[email]': {
          validators: {
            callback: {
              message: Hillary.notificationsValidationRecipientSelection,
              callback(_input) {
                return recipientValidationCallback(_form);
              },
            },
          },
        },
        'notification[mobile_number]': {
          validators: {
            regexp: {
              regexp: $('input[name="notification[mobile_number]"]').data('masked-fv-regexp-regexp'),
              message: $('input[name="notification[mobile_number]"]').data('masked-fv-regexp-message'),
            },
            callback: {
              message: Hillary.notificationsValidationRecipientSelection,
              callback(_input) {
                return recipientValidationCallback(_form);
              },
            },
          },
        },
        triggerTargetCollection: {
          selector: '[data-trigger-target-collection]',
          trigger: 'change',
          validators: {
            enabled: true,
            callback: {
              message: Hillary.notificationsValidationCardsOrAccountSelection,
              callback(_input) {
                let hasSelection = false;
                const fv = _form.data('formValidation');
                const triggerTargetCollection = document.querySelectorAll('[data-trigger-target-collection]');
                triggerTargetCollection.forEach((element) => {
                  const selection = element.value;
                  if (selection.length) {
                    hasSelection = true;
                  }
                });
                if (hasSelection) {
                  triggerTargetCollection.forEach(field =>
                    fv.updateStatus($(field), 'VALID', 'callback'));
                  return true;
                }
                triggerTargetCollection.forEach(field =>
                  fv.updateStatus($(field), 'INVALID', 'callback'));
                return false;
              },
            },
          },
        },
        daySelection: {
          selector: '[data-day-selection]',
          trigger: 'change',
          validators: {
            enabled: true,
            callback: {
              callback(_input) {
                let oneSelected = false;
                const errorField = $('[data-trigger-validation-field]');
                const daySelectionElements = document.querySelectorAll('[data-day-selection]');
                daySelectionElements.forEach((element) => {
                  if ($(element)[0].checked) {
                    oneSelected = true;
                  }
                });
                if (oneSelected) {
                  errorField.text('');
                  return true;
                }
                errorField.text(Hillary.notificationsValidationDaySelection);
                return false;
              },
            },
          },
        },
        'notification[trigger_attributes][upper_limit]': {
          trigger: 'change',
          validators: {
            callback: {
              message: Hillary.volumeUpperLimitError,
              callback(_input) {
                return validateLimitPresence(_form) && validateLimits(_form);
              },
            },
          },
        },
        'notification[trigger_attributes][lower_limit]': {
          trigger: 'change',
          validators: {
            callback: {
              callback(_input) {
                if (!validateLimitPresence(_form)) {
                  return false;
                }
                validateLimits(_form);
                return true;
              },
            },
          },
        },
      },
    });
  }
}
