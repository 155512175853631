import Tagify from '@yaireo/tagify';

function updateButton(e) {
  const elements = $('.tagify--notAllowed');
  if (elements.length) {
    $('[data-invoice-update-button]').prop('disabled', true);
  } else {
    $('[data-invoice-update-button]').prop('disabled', false);
  }
}

export default class TagInputEnabled {
  static start() {
    $(document).ready(() => {
      const inputElm = document.querySelector('[data-tags-input]');
      if (!inputElm) {
        return;
      }
      const tagify = new Tagify(inputElm, {
        originalInputValueFormat: valuesArr =>
          valuesArr.map(item => item.value).join(','),
        keepInvalidTags: true,
        pattern: window.Hillary.emailPattern,
        backspace: 'edit',
        callbacks: {
          invalid: updateButton,
          add: updateButton,
          remove: updateButton,
          'edit:updated': updateButton,
        },
      });
    });
  }
}

