import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['button', 'timer', 'container'];
  }

  connect() {
    if (this.needsTimer()) {
      this.runTimer();
    }
  }

  needsTimer() {
    const parsedTime = parseInt(this.timerTarget.innerHTML, 10);

    return parsedTime > 0;
  }

  runTimer() {
    this.disableButton();
    let parsedTime = parseInt(this.timerTarget.innerHTML, 10);
    const secondInMs = 1000;
    const timerRunTime = secondInMs * parsedTime;

    const interval = setInterval(() => {
      parsedTime -= 1;
      this.timerTarget.textContent = parsedTime;
    }, secondInMs);

    setTimeout(() => {
      this.hideContainer();
      this.enableButton();
      clearInterval(interval);
    }, timerRunTime);
  }

  hideContainer() {
    this.containerTarget.classList.toggle('d-none');
  }

  disableButton() {
    this.buttonTarget.classList.toggle('disabled');
  }

  enableButton() {
    this.buttonTarget.classList.toggle('disabled');
  }
}
