$(document).ready(() => {
  if ($('.steps-bar-container').length !== 0) {
    const activeStep = document.getElementsByClassName('step active')[0];

    // `block: 'end'` attempts to place the stepbar on the bottom of the viewport
    // Both arguments are needed for `scrollIntoView` because this method scrolls in
    // both directions, and when no vertical scroll argument is specified, the header
    // will be left out of sight when refreshing. This also only works as long as the
    // header doesn't get too big.
    activeStep.scrollIntoView({ block: 'end', inline: 'center' });
  }
});
