import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['summary', 'accounts', 'cards', 'timeFrom', 'timeTo', 'triggerType', 'NotificationTimeTrigger',
      'NotificationDayTrigger', 'NotificationCountTrigger', 'transactionCount', 'NotificationVolumeTrigger',
      'upperVolumeLimit', 'lowerVolumeLimit', 'NotificationAmountTrigger', 'amountTriggerPeriod',
      'amountTriggerAmount', 'NotificationAggregatedVolumeTrigger', 'aggregatedVolumeTriggerPeriod',
      'aggregatedVolumeTriggerAmount', 'NotificationProductTrigger', 'product'];
  }

  connect() {
    $(document).ready(() => {
      this.buildSummary();
      this.changeTriggerFields();
    });
  }

  changeTriggerFields() {
    ['NotificationTimeTrigger', 'NotificationDayTrigger', 'NotificationCountTrigger',
      'NotificationVolumeTrigger', 'NotificationAmountTrigger',
      'NotificationAggregatedVolumeTrigger', 'NotificationProductTrigger'].forEach((trigger) => {
      this[`${trigger}Target`].classList.add('d-none');
    });
    this[`${this.triggerTypeTarget.value}Target`].classList.remove('d-none');
    this.buildSummary();
  }

  buildSummary() {
    let text = Hillary.notificationsSummaryTemplate;
    this.transactionsCount = 1; // can be overriden in the corresponding trigger
    const trigger = this.buildTriggerText();
    const occurs = this.transactionsCount === 1 ? Hillary.notificationsSummaryOccursOne :
      Hillary.notificationsSummaryOccursOther;
    text = text.replace('$entities', this.buildEntitiesText());
    text = text.replace('$trigger', trigger);
    text = text.replace('$occurs', occurs);
    this.summaryTarget.innerHTML = trigger === '' ? '' : text;
  }

  buildTriggerText() {
    const type = this.triggerTypeTarget.value;
    return this[`build${type}`](); // conventional method calling by `trigger_type`
  }

  buildNotificationTimeTrigger() {
    if (this.timeFromTarget.value === '' || this.timeToTarget.value === '') {
      return '';
    }
    return Hillary.notificationsSummaryTriggerTimeTemplate
      .replace('$from', this.timeFromTarget.value)
      .replace('$to', this.timeToTarget.value);
  }

  buildNotificationDayTrigger() {
    const days = $('[data-notifications-target="NotificationDayTrigger"]')
      .find('input[type="checkbox"]:checked + label')
      .map((i, e) => $(e).html()).toArray();
    if (!days.length) {
      return '';
    }
    return Hillary.notificationsSummaryTriggerDayTemplate
      .replace('$days', days.join(', '));
  }

  buildNotificationCountTrigger() {
    this.transactionsCount = parseInt(this.transactionCountTarget.value, 10);
    return this.pluralizeText(
      this.transactionCountTarget.value,
      Hillary.notificationsSummaryTriggerTransactionCountTemplateOne,
      Hillary.notificationsSummaryTriggerTransactionCountTemplateOther,
    );
  }

  buildNotificationVolumeTrigger() {
    const lower = this.lowerVolumeLimitTarget.value;
    const upper = this.upperVolumeLimitTarget.value;
    const result = [];
    if (lower) {
      result.push(Hillary.notificationsSummaryTriggerVolumeLower.replace('$amount', lower));
    }
    if (upper) {
      result.push(Hillary.notificationsSummaryTriggerVolumeUpper.replace('$amount', upper));
    }
    if (!result.length) {
      return '';
    }
    return Hillary.notificationsSummaryTriggerVolumeTemplate.replace('$volume', result.join(' '));
  }

  buildNotificationAmountTrigger() {
    if (this.amountTriggerAmountTarget.value === '') {
      return '';
    }
    this.transactionsCount = 2;
    const period = this.amountTriggerPeriodTargets[0].checked ? Hillary.notificationsSummaryTriggerAmountDaily :
      Hillary.notificationsSummaryTriggerAmountMonthly;
    return Hillary.notificationsSummaryTriggerAmountTemplate
      .replace('$period', period)
      .replace('$amount', `${this.amountTriggerAmountTarget.value} CHF`);
  }

  buildNotificationAggregatedVolumeTrigger() {
    this.transactionsCount = 2;
    const periodElement = this.aggregatedVolumeTriggerPeriodTargets[0];
    const period = periodElement.checked ? Hillary.notificationsSummaryTriggerAmountDaily :
      Hillary.notificationsSummaryTriggerAmountMonthly;
    const liter = Hillary.notificationsSummaryTriggerAggregatedVolumeLiter;
    return Hillary.notificationsSummaryTriggerAggregatedVolumeTemplate
      .replace('$period', period)
      .replace('$amount', `${this.aggregatedVolumeTriggerAmountTarget.value} ${liter}`);
  }

  buildNotificationProductTrigger() {
    const label = this.productTarget.options[this.productTarget.selectedIndex].innerHTML;
    return Hillary.notificationsSummaryTriggerProductTemplate.replace('$product', label);
  }

  buildEntitiesText() {
    const entities = [];
    const accountsCount = $(this.accountsTarget).select2('data').length;
    const cardsCount = $(this.cardsTarget).select2('data').length;

    if (accountsCount > 0) {
      const text = this.pluralizeText(
        accountsCount,
        Hillary.notificationsSummaryAccountsTextOne,
        Hillary.notificationsSummaryAccountsTextOther,
      );
      entities.push(text);
    }
    if (cardsCount > 0) {
      const text = this.pluralizeText(
        cardsCount,
        Hillary.notificationsSummaryCardsTextOne,
        Hillary.notificationsSummaryCardsTextOther,
      );
      entities.push(text);
    }
    return entities.join(` ${Hillary.notificationsSummaryAnd} `);
  }

  pluralizeText(count, one, other) {
    const text = count === 1 ? one : other;
    return text.replace('$count', count);
  }
}
