export default class TwoFactorCountdown {
  start() {
    this.setupCountdown = this.setupCountdown.bind(this);
    this.placeholder = '%{countdown}';
    this.setupCountdown('data-enable-after', (element) => {
      element.attr('disabled', null);
      element.removeClass('disabled');
    });

    this.setupCountdown('data-hide-after', element => element.hide());
  }

  setupCountdown(selector, whenDone) {
    return $(`[${selector}]`).each((_index, element) => {
      const $element = $(element);
      const originalButtonTitle = $element.html();
      const countdownFrom = parseInt($element.attr(selector), 10);
      if (countdownFrom <= 0) {
        this.updateButtonText($element, originalButtonTitle, 0);
        whenDone($element);
        return;
      }
      let counter = countdownFrom;
      this.updateButtonText($element, originalButtonTitle, counter);
      const intervalID = setInterval(() => {
        counter -= 1;
        this.updateButtonText($element, originalButtonTitle, counter);
        if (counter === 0) {
          clearInterval(intervalID);
          whenDone($element);
        }
      }, 1000);
    });
  }

  updateButtonText($element, originalTitle, count) {
    let placeholder;
    if (count <= 0) {
      placeholder = $element.attr('data-enabled-placeholder') || '';
    } else {
      placeholder = $element.attr('data-countdown-placeholder') || this.placeholder;
      placeholder = placeholder.replace(this.placeholder, this.formatTime(count));
    }
    return $element.html(originalTitle.replace(this.placeholder, placeholder));
  }

  formatTime(time) {
    const minutes = parseInt(time / 60, 10);
    const seconds = time % 60;
    return `${minutes}min ${seconds}s`;
  }
}
