export default class FilterClearer {
  static start() {
    $(document).ready(() => {
      const form = $('[data-filter-form]');
      const resetButton = $('[data-filter-clear]');
      if (resetButton.length) {
        $(resetButton).on('click', () => {
          FilterClearer.clearForm(form);
        });
      }
    });
  }

  static clearForm(form) {
    $(form)
      .find('input:text, input:password, input:file, input[type="number"], input[type="search"], textarea')
      .val('');
    $(form).find('select').val([]);
    $(form).submit();
  }
}
