export default class QuoteSanitizer {
  constructor() {
    $(document).on('change', 'input[type="text"]', event => this.sanitize(event));
  }

  sanitize(event) {
    const { target } = event;
    target.value = event.target.value.replace(/’/g, '\'').replace(/—/g, '-');
  }
}
