import { Controller } from 'stimulus';

export default class SelectFilterController extends Controller {
  static get targets() {
    return ['select', 'inactiveCheckbox'];
  }

  connect() {
    this.toggleInactive({ target: this.inactiveCheckboxTarget });
  }
  toggleInactive(e) {
    const hideInactive = e.target.checked;
    this.selectTargets.forEach((select) => {
      select.options.forEach((option) => {
        if (hideInactive && option.dataset.blocked === 'true') {
          option.setAttribute('disabled', true);
        } else {
          option.removeAttribute('disabled');
        }
      });
    });
  }
}
