export default class ValidationSwitcher {
  constructor(container, enabled) {
    container.find('input, select').each((idx, el) => {
      const field = el.getAttribute('name');
      if (!$('form').data('formValidation').options.fields[field]) {
        return;
      }
      $('form').formValidation('enableFieldValidators', field, enabled);
      $('form').formValidation('revalidateField', field);
      $('form').formValidation('disableSubmitButtons', false);
    });
  }
}
