export default class DocumentSelector {
  constructor() {
    this.initializePreview = this.initializePreview.bind(this);
    this.selector = $('[name="user[id_scan_type]"]');
    if (this.selector.length) {
      this.selector.on('change', () => this.changeSectionsVisibility());
      this.changeSectionsVisibility();
      this.initializePreview();
    }
  }

  changeSectionsVisibility() {
    const identitySelected = this.selector.filter(':checked').val();
    const idScanBack = $('[data-id-scan-back]');
    const isIdentityCard = identitySelected === 'identity_card';
    idScanBack.toggle(isIdentityCard);
    if (!isIdentityCard) {
      this.clearScanBackInput();
    }
  }

  clearScanBackInput() {
    $('[name="user[id_scan_back]"]').val('');
    $('#user_id_scan_back_preview').attr('src', null);
  }

  initializePreview() {
    return $('[data-preview]').change(el => this.readURL(el.currentTarget));
  }

  readURL(fileInput) {
    if (fileInput.files && fileInput.files[0]) {
      const reader = new FileReader();
      reader.onload = e => $(`#${fileInput.id}_preview`).attr('src', e.target.result);
      reader.readAsDataURL(fileInput.files[0]);
    }
  }
}
