import TwoFactorCountdown from './two_factor_countdown';

window.HillaryVerificationsNew = class HillaryVerificationsNew {
  constructor() {
    new TwoFactorCountdown().start();
  }
};

window.HillaryLoginsNew = class HillaryLoginsNew {
  constructor() {
    new TwoFactorCountdown().start();
  }
};
