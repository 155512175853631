import { Controller } from 'stimulus';
import $ from 'jquery';
import * as Sentry from '@sentry/browser';
import IdentityDropzone from '../../public/identity_dropzone';

function supported(elem, attribute) {
  const el = document.createElement(elem);
  el.setAttribute(attribute, true);
  return !!el[attribute];
}

export default class extends Controller {
  static get targets() {
    return [
      'backUpload', 'backPreviewField', 'backUploadField', 'backDiscard',
      'frontUpload', 'frontPreviewField', 'frontUploadField', 'frontDiscard',
      'dropzone', 'identityCardType', 'identityCardInput', 'passportCardInput', 'passportCardType',
      'permitCardInput', 'permitCardType', 'submitButton', 'frontMessage', 'cameraNotice',
    ];
  }

  connect() {
    this.dropzoneTargets.forEach((element) => {
      new IdentityDropzone($(element)).setup(
        this.onIdentityCardUploaded.bind(this),
        this.onIdentityCardUploadError.bind(this),
      );
    });

    this.updateDocumentTypeSelection();
    this.hideUploadFieldIfUploaded();
    this.identificationTypeSwitched();
    this.submitButtonActivated();

    this.checkForCameraPermission().catch(_reason => this.cameraNoticeTarget.classList.add('show'));
  }

  checkForCameraPermission() {
    if (!supported('input', 'capture')) {
      Sentry.captureMessage('Input capture is not supported');
      return Promise.reject(new Error('Input capture is not supported'));
    }

    if (!('mediaDevices' in navigator) || !('enumerateDevices' in navigator.mediaDevices)) {
      Sentry.captureMessage('No access to the camera');
      return Promise.reject(new Error('No access to the camera'));
    }

    return new Promise((resolve, reject) => {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        if (devices.filter(device => /video/.test(device.kind)).length >= 1) {
          resolve();
        } else {
          reject(new Error('No camera found'));
        }
      }).catch(reject);
    });
  }

  clearUploadedFile(event) {
    event.preventDefault(); // Prevent page reload

    const uploadContainer = event.target.parentElement;
    const uploadFieldContainer = uploadContainer.getElementsByClassName('upload-field')[0];
    const dropzoneElement = uploadFieldContainer.getElementsByClassName('dropzone dz-clickable')[0];
    const isFront = uploadContainer === this.frontUploadTarget;
    dropzoneElement.classList.remove('dz-started');
    dropzoneElement.getElementsByClassName('dz-preview')[0].remove();
    this.purgeUploadedFile(dropzoneElement, isFront);
    if (isFront) {
      this.frontUploaded = 0;
    } else {
      this.backUploaded = 0;
    }

    this.hideUploadFieldIfUploaded();
    this.submitButtonActivated();
  }

  purgeUploadedFile(dropzoneElement, isFront) {
    if ((isFront && !this.frontUploaded) || (!isFront && !this.backUploaded)) {
      return;
    }
    const $dropzone = $(dropzoneElement);
    const url = $dropzone.data('url');
    const additionalParams = $dropzone.data('additional');
    const formData = new FormData();
    formData.append(`user[remove_id_scan_${isFront ? 'front' : 'back'}]`, '1');
    Object.entries(additionalParams).forEach(([param, data]) => {
      formData.append(`user[${param}]`, data);
    });
    Rails.ajax({
      type: 'put',
      url,
      dataType: 'json',
      data: formData,
    });
  }

  switchIdentificationType(event) {
    const type = event.currentTarget.getAttribute('data-scan-type');
    const inputField = document.getElementById(`user_id_scan_type_${type}`);

    this.uncheckAllDocumentTypes();
    inputField.setAttribute('checked', 'checked');
    this.updateDocumentTypeSelection();
    this.identificationTypeSwitched();
  }

  uncheckAllDocumentTypes() {
    this.identityCardInputTarget.removeAttribute('checked');
    this.passportCardInputTarget.removeAttribute('checked');
    this.permitCardInputTarget.removeAttribute('checked');
  }

  updateDocumentTypeSelection() {
    const activeCard = this.getActiveDocumentType();
    document.getElementsByClassName('identity-card-type').forEach((element) => {
      element.classList.remove('active');
    });
    activeCard.getElementsByClassName('identity-card-type')[0].classList.add('active');
  }

  changeBackSideVisibility(elements, showBackSide) {
    const displayStyle = showBackSide ? 'block' : 'none';

    elements.forEach((element) => {
      element.style.display = displayStyle;
    });
  }

  hideUploadFieldIfUploaded() {
    if (this.frontUploaded) {
      this.frontUploadFieldTarget.style.display = 'none';
      this.frontPreviewFieldTarget.style.display = 'block';
      this.frontDiscardTarget.style.display = 'block';
    } else {
      this.frontUploadFieldTarget.style.display = 'block';
      this.frontPreviewFieldTarget.style.display = 'none';
      this.frontDiscardTarget.style.display = 'none';
    }

    if (this.backUploaded) {
      this.backUploadFieldTarget.style.display = 'none';
      this.backPreviewFieldTarget.style.display = 'block';
      this.backDiscardTarget.style.display = 'block';
    } else {
      this.backUploadFieldTarget.style.display = 'block';
      this.backPreviewFieldTarget.style.display = 'none';
      this.backDiscardTarget.style.display = 'none';
    }
  }

  identificationTypeSwitched() {
    const showBackSide = this.identityCardInputTarget.checked || this.permitCardInputTarget.checked;
    let elements = [];

    if (this.hasFrontUploadTarget && this.hasBackUploadTarget) {
      elements = [this.frontMessageTarget, this.backUploadTarget];
    }

    this.setDocumentTypeForDropzone();
    this.changeBackSideVisibility(elements, showBackSide);
    this.submitButtonActivated();
  }

  setDocumentTypeForDropzone() {
    const documentType = this.getActiveDocumentInput();
    this.dropzoneTargets.forEach((element, _index) => {
      $(element).attr('data-id-type', documentType);
    });
  }

  getActiveDocumentInput() {
    if (this.identityCardInputTarget.checked) {
      return this.identityCardInputTarget.value;
    } else if (this.passportCardInputTarget.checked) {
      return this.passportCardInputTarget.value;
    }
    return this.permitCardInputTarget.value;
  }

  getActiveDocumentType() {
    if (this.identityCardInputTarget.checked) {
      return this.identityCardTypeTarget;
    } else if (this.passportCardInputTarget.checked) {
      return this.passportCardTypeTarget;
    }
    return this.permitCardTypeTarget;
  }

  onIdentityCardUploaded(dropzoneElement, file) {
    if (dropzoneElement.attr('id') === 'front-dropzone') {
      this.frontPreviewFieldTarget.getElementsByTagName('img')[0].src = file.dataURL;
      this.frontUploaded = 1;
    }

    if (dropzoneElement.attr('id') === 'back-dropzone') {
      this.backPreviewFieldTarget.getElementsByTagName('img')[0].src = file.dataURL;
      this.backUploaded = 1;
    }

    this.hideUploadFieldIfUploaded();
    this.submitButtonActivated();
  }

  onIdentityCardUploadError(dropzoneElement, _errorMessage) {
    if (dropzoneElement.attr('id') === 'front-dropzone') {
      this.frontDiscardTarget.style.display = 'block';
    } else {
      this.backDiscardTarget.style.display = 'block';
    }
  }

  submitButtonActivated() {
    const identityCardCondition = this.identityCardInputTarget.checked && this.frontUploaded && this.backUploaded;
    const otherCardCondition = !this.identityCardInputTarget.checked && this.frontUploaded;

    this.submitButtonTarget.disabled = !identityCardCondition && !otherCardCondition;
  }

  // frontUploaded and backUploaded use '0'/'1' instead of 'false'/'true' and parse
  // them to integer for easier boolean conversion. 'false' and 'true' have more
  // cases to take care of, such as !'true' = false, but !'false' = false
  get frontUploaded() {
    return parseInt(this.data.get('frontUploaded'), 10);
  }

  set frontUploaded(value) {
    this.data.set('frontUploaded', value);
  }

  get backUploaded() {
    return parseInt(this.data.get('backUploaded'), 10);
  }

  set backUploaded(value) {
    this.data.set('backUploaded', value);
  }
}
