export default class PreviousAddressSwitcher {
  constructor() {
    this.previousAddressSwitcher = $('[name="user[address_since_more_than_two_years]"]');
    this.addressSinceAt = $('[data-address-since-at]');
    this.previousAddressBlock = $('[data-previous-address-block]');
    this.switchAddressSinceAt();
    this.previousAddressSwitcher.on('change', () => this.switchAddressSinceAt());
    if ($('#user_address_since_at').length && $('#user_address_since_at').val().length) {
      const dateParts = $('#user_address_since_at').val().split('.');
      const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
      this.addressSinceAtChanged(date);
    }
    $('#user_address_since_at').on('changeDate', (e) => {
      this.addressSinceAtChanged(e.date);
    });
    $('#user_address_since_at').on('change', (e) => {
      this.addressSinceAtChanged($(e.target).datepicker('getDate'));
    });
  }

  addressSinceAtChanged(date) {
    const selectedDate = date;
    const twoYearsAgo = new Date();
    twoYearsAgo.setYear(twoYearsAgo.getFullYear() - 2);
    if (selectedDate > twoYearsAgo) {
      this.previousAddressBlock.show();
    } else {
      this.previousAddressBlock.hide();
      this.clearPreviousAddressFields();
    }
  }

  switchAddressSinceAt() {
    if (!this.previousAddressSwitcher.length) {
      return;
    }
    const checked = this.previousAddressSwitcher.filter(':checked').val() === 'no';
    this.addressSinceAt.toggle(checked);
    this.previousAddressBlock.toggle(checked);
    if (!checked) {
      this.clearPreviousAddress();
    }
  }

  clearPreviousAddress() {
    $('[name="user[address_since_at]"]').val('');
    this.clearPreviousAddressFields();
  }

  clearPreviousAddressFields() {
    $('[name="user[address_previous]"]').val('');
    $('[name="user[street_number_previous]"]').val('');
    $('[name="user[zip_code_previous]"]').val('');
    $('[name="user[city_previous]"]').val('');
  }
}
