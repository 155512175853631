import './application';
import '../../vendor/javascripts/codepage850';
import '../../vendor/javascripts/formvalidation/formValidation';
import '../../vendor/javascripts/formvalidation/bootstrap';
import '../../src/javascripts/portal/graph';
import CardOperationValidator from '../../src/javascripts/portal/card_operation_validator';
import RemoteDownload from '../../src/javascripts/portal/remote-download';
import InlineSubmittable from '../../src/javascripts/portal/inline_submittable';
import GraphCollapsible from '../../src/javascripts/portal/graph_collapsible';
import PasswordSwitcher from '../../src/javascripts/common/password_switcher';
import InvitationLetterValidator from '../../src/javascripts/portal/invitation_letter_validator';
import MobileNumberTrimmer from '../../src/javascripts/public/mobile_number_trimmer';
import ReadonlyForm from '../../src/javascripts/portal/readonly_form';
import TagInputEnabler from '../../src/javascripts/public/tag_input';
import FilterClearer from '../../src/javascripts/public/filter_clearer';
import MobileDropDown from '../../src/javascripts/public/mobile_dropdown';
import NotificationValidator from '../../src/javascripts/portal/notification_validator';

$(document).ready(() => {
  new PasswordSwitcher();
  new NotificationValidator();
});

window.HillaryCardsShow = class HillaryCardsShow {
  constructor() {
    new CardOperationValidator();
  }
};

window.HillaryInvitationLetterRequestNew = class HillaryInvitationLetterRequestNew {
  constructor() {
    new InvitationLetterValidator();
  }
};

RemoteDownload.start();
FilterClearer.start();
MobileDropDown.start();
InlineSubmittable.start();
ReadonlyForm.start();
GraphCollapsible.start();
TagInputEnabler.start();
new MobileNumberTrimmer();
