export default class InlineSubmittable {
  static start() {
    $(document).ready(() => {
      $('[data-inline-submit]').each((_i, element) => {
        const $element = $(element);
        const form = document.querySelector($element.attr('href'));

        $element.on('click', (e) => {
          e.preventDefault();
          e.stopPropagation();

          form.submit();
        });
      });
    });
  }
}
