import Chart from 'chart.js';

function formatMoney(amount) {
  return new Intl.NumberFormat('de-CH', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(amount);
}

export default class Graphs {
  static initialize(selector) {
    $(selector).each((_i, element) => {
      const $chart = $(element);
      const data = JSON.parse($chart.attr('data-chart-data'));
      const labels = JSON.parse($chart.attr('data-chart-labels'));
      const datasetLabel = $chart.attr('data-chart-dataset-label');

      new Chart($chart, {
        type: 'line',
        title: {
          display: false,
        },
        data: {
          labels,
          datasets: [{
            label: datasetLabel,
            backgroundColor: '#940619',
            borderColor: '#940619',
            pointRadius: 0.1,
            data,
          }],
        },
        options: {
          maintainAspectRatio: false,
          lineTension: 3,
          legend: {
            display: false,
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
              minimum: 0,
            }],
            yAxes: [{
              ticks: {
                callback: value => (value === 0 ? 0 : formatMoney(value)),
              },
            }],
          },
        },
      });
    });
  }
}

$(document).ready(() => {
  Graphs.initialize('[data-chart-data]');
});

window.initializeGraphs = Graphs.initialize;
