export default class PermitSelector {
  constructor() {
    this.checkNationality = this.checkNationality.bind(this);
    this.nationalityType = $('[name="user[nationality_type]"]');
    this.permit = $('.user_permit_type');
    this.nationality = $('.user_nationality_id');
    this.inSwitzerlandSince = $('*[data-field="in-switzerland-since"]');
    this.checkNationality();
    this.nationalityType.on('change', () => this.checkNationality());
  }

  checkNationality() {
    if (this.nationalityType.filter(':checked').val() === 'other') {
      this.permit.show();
      this.nationality.show();
      this.inSwitzerlandSince.show();
      this.permit.find('select').prop('disabled', false);
      this.nationality.find('select').prop('disabled', false);
      this.inSwitzerlandSince.find('input').prop('disabled', false);
      if ($('#user_country_id :selected').text() === Hillary.switzerland) {
        $('#user_permit_type > option').each((index, element) => $(element).show());
      } else {
        $('#user_permit_type > option').each((index, element) => {
          if (['', 'D', 'G'].indexOf($(element).val()) === -1) {
            $(element).hide();
            if ($(element).is(':selected')) {
              $('#user_permit_type').val('');
            }
          }
        });
      }
    } else {
      this.permit.hide();
      this.nationality.hide();
      this.inSwitzerlandSince.hide();
      this.permit.find('select').prop('disabled', true);
      this.nationality.find('select').prop('disabled', true);
      this.inSwitzerlandSince.find('input').prop('disabled', true);
      $('#user_permit_type > option').each((index, element) => $(element).show());
    }
  }
}
