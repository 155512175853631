(function ($) {
  FormValidation.Validator.mastercardIban = {
    validate(validator, $field, options) {
      const value = $field.val();
      swissCheck = FormValidation.Validator.iban.validate(validator, $field, { country: 'CH' });
      if (swissCheck.valid) { return swissCheck; }

      lichtensteinCheck = FormValidation.Validator.iban.validate(validator, $field, { country: 'LI' });
      if (lichtensteinCheck.valid) { return lichtensteinCheck; }

      return value.startsWith('LI') ? lichtensteinCheck : swissCheck;
    },
  };
}(window.jQuery));
