import 'select2/dist/js/select2.full';
import 'select2/dist/js/i18n/de';
import 'select2/dist/js/i18n/fr';
import 'select2/dist/js/i18n/it';

export default class MigrolDropdown {
  static start() {
    $(document).ready(() => {
      $('[data-migrol-dropdown]').each(function () {
        const dispatchNativeEvent = () => {
          const event = new Event('change', { bubbles: true });
          this.dispatchEvent(event);
        };

        $(this).select2({
          theme: 'migrol',
          dropdownCssClass: $(this).data('hide-locked') ? 'hide-locked' : '',
          allowClear: $(this).data('allow-clear') !== undefined,
          language: MigrolDropdown._dropdownLanguage(),
          templateSelection(tag, container) {
            const $option = $(`[data-migrol-dropdown] option[value="${tag.id}"]`);
            if ($option.attr('locked') === 'true') {
              $(container).addClass('locked-tag');
              tag.locked = true;
            }
            return tag.text;
          },
        }).on('select2:unselecting', (e) => {
          if ($(e.params.args.data.element).attr('locked') === 'true') {
            e.select2.pleaseStop();
          }
        }).on('select2:select', dispatchNativeEvent)
          .on('select2:unselect', dispatchNativeEvent);
      });
    });
  }

  static _dropdownLanguage() {
    const browserLanguage = navigator.language;

    switch (true) {
      case /de-?/.test(browserLanguage):
        return 'de';
      case /it-?/.test(browserLanguage):
        return 'it';
      case /fr-?/.test(browserLanguage):
        return 'fr';
      default:
        return 'en';
    }
  }
}
