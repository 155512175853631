import CityUpdaterMerchant from './city_updater_merchant';
import CityUpdater from './city_updater';
import ZipCodeValidator from './zip_code_validator';
import DateValidator from './date_validator';
import NumberInput from './number_input';
import PermitSelector from './permit_selector';
import FullNameValidator from './full_name_validator';
import AddressValidator from './address_validator';
import './codepage850';
import './mastercard_iban_validator';
import PostalAddressValidator from './postal_address_validator';

export default class ApplicationFormValidator {
  constructor() {
    this.updateCity = this.updateCity.bind(this);
    this.fetchFormFields();
    const _form = this.form;

    const options = {
      locale: Hillary.shortLocale,
      trigger: 'blur',
      fields: {
        'user[birthdate]': {
          validators: {
            date: Hillary.initialBirthdateOptions,
          },
        },
        'user[in_switzerland_since]': {
          trigger: 'keyup',
          validators: {
            date: {
              format: 'DD.MM.YYYY',
              max: Hillary.today,
              min: Hillary.minDate,
            },
          },
        },
        'user[address_since_at]': {
          trigger: 'keyup',
          validators: {
            date: {
              format: 'DD.MM.YYYY',
              max: Hillary.today,
              min: Hillary.minDate,
            },
          },
        },
        'user[second_card_birthdate]': {
          trigger: 'keyup',
          validators: {
            date: Hillary.birthdateOptions,
          },
        },
        'user[accept_terms]': {
          excluded: false,
          validators: {
            notEmpty: {
              message: Hillary.requiredMessage,
            },
          },
        },
        'user[accept_data_privacy]': {
          excluded: false,
          validators: {
            notEmpty: {
              message: Hillary.requiredMessage,
            },
          },
        },
        'user[first_name]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
        'user[last_name]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
        'user[full_name]': {
          excluded: false,
          validators: {
            stringLength: Hillary.initialFullnameOptions,
          },
        },
        'user[address]': {
          validators: {
            codepage850: {
              enabled: true,
            },
          },
        },
        'user[permit_type]': {
          validators: {
            notEmpty: {
              message: Hillary.requiredMessage,
            },
          },
        },
        'user[nationality_type]': {
          trigger: 'change',
          validators: {
            notEmpty: {
              message: Hillary.requiredMessage,
            },
          },
        },
        'user[yearly_gross_income]': {
          trigger: 'change',
          validators: {
            between: {
              min: 0,
              max: 999999,
            },
          },
        },
        'user[payment_method]': {
          trigger: 'change',
          validators: {
            notEmpty: {
              message: Hillary.requiredMessage,
            },
          },
        },
        'user[payment_account]': {
          trigger: 'change',
          validators: {
            notEmpty: {
              message: Hillary.requiredMessage,
            },
            mastercardIban: {
              enabled: false,
            },
          },
        },
        'user[embossing_line1]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
        'user[embossing_line2]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
        'user[embossing_line3]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
        'user[second_card_embossing_line1]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
        'user[second_card_embossing_line2]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
        'user[second_card_embossing_line3]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
        'user[cumulus_number]': {
          trigger: 'change',
          verbose: false,
          validators: {
            remote: {
              url: '/cumulus/validate',
              type: 'GET',
              delay: 200,
              async: true,
              data() {
                return { locale: Hillary.locale };
              },
            },
          },
        },
      },
    };

    if ($('[data-aggregated-phone-field]').length > 0) {
      $('[data-aggregated-phone-field]').each((_idx, el) => {
        options.fields[el.name] = {
          enabled: true,
          validators: {
            regexp: {
              enabled: true,
              regexp: $(el).data('masked-fv-regexp-regexp'),
              message: $(el).data('masked-fv-regexp-message'),
            },
            callback: {
              enabled: true,
              message: Hillary.atLeastOnePhoneNumber,
              callback(_input) {
                const phoneElements = document.querySelectorAll('[data-aggregated-phone-field]');
                const emptyFields = [];
                const fv = _form.data('formValidation');
                phoneElements.forEach((element) => {
                  if (element.value === '') {
                    emptyFields.push(element);
                  }
                });
                if (emptyFields.length === phoneElements.length) {
                  phoneElements.forEach(field => fv.updateStatus($(field), 'INVALID', 'callback'));
                  return false;
                }
                emptyFields.forEach(field => fv.updateStatus($(field), 'VALID', 'callback'));
                return true;
              },
            },
          },
        };
      });
    } else {
      options.fields['user[mobile_number]'] = {
        validators: {
          notEmpty: {
            enabled: false,
            message: Hillary.requiredMessage,
          },
        },
      };
    }

    this.form.formValidation(options).on('err.validator.fv', (e, data) => {
      this.updateCity(data);
      this.clearServerErrors(data);
    }).on('success.validator.fv', (e, data) => {
      this.updateCity(data);
      this.clearServerErrors(data);
    });
    new ZipCodeValidator(this.form, 'user[zip_code]', () =>
      ({ country_id: $('#user_country_id').find(':selected').val() }));
    new ZipCodeValidator(this.form, 'user[zip_code_previous]', () =>
      ({ country_id: $('#user_nationality_previous_id').find(':selected').val() }));

    if (Hillary.canValidateEmail) {
      this.form.formValidation('addField', 'user[email]', {
        trigger: 'change',
        verbose: false,
        validators: {
          remote: {
            url: '/emails/validate',
            type: 'GET',
            delay: 200,
            async: true,
            data() {
              return {
                user: { id: $('#user_id').val(), tenant_code: $('#user_tenant_code').val() },
                locale: Hillary.locale,
              };
            },
          },
        },
      });
    }

    this.form.formValidation('addField', 'user[liable_person_type]', {
      trigger: 'change',
      validators: {
        notEmpty: {
          message: Hillary.requiredMessage,
        },
      },
    });

    new DateValidator();
    new NumberInput('user[pin_code]');
    this.permitSelector = new PermitSelector();
    this.updateMaxZipLengthByCountry('user[zip_code]', '#user_country_id');

    this.city.on('change', (e) => {
      this.selected_city.val(this.city.val());
      $('form').formValidation('revalidateField', 'user[city]');
    });

    this.city_previous.on('change', e => $('form').formValidation('revalidateField', 'user[city_previous]'));

    $('#user_country_id').on('change', (e) => {
      this.updateMaxZipLengthByCountry('user[zip_code]', '#user_country_id');
      this.cityUpdaters.zip_code.clearCurrentInput();
      this.updatePermitRequired();
    });

    const userNationalityPreviousId = $('#user_nationality_previous_id');
    if (userNationalityPreviousId.length) {
      this.updatePreviousNationality(false);
      userNationalityPreviousId.on('change', e => this.updatePreviousNationality(true));
    }


    $('#user_accept_terms').on('change', () => $('form').formValidation('revalidateField', 'user[accept_terms]'));
    $('#user_accept_data_privacy').on('change', () => $('form')
      .formValidation('revalidateField', 'user[accept_data_privacy]'));

    new FullNameValidator('user[first_name]', 'user[last_name]', 'user[full_name]');
    new FullNameValidator('user[second_card_first_name]', 'user[second_card_last_name]', 'user[second_card_full_name]');

    new AddressValidator('user[address]', 'user[street_number]', 'user[full_address]');
    new AddressValidator('user[address_previous]', 'user[street_number_previous]', 'user[full_address_previous]');
    new PostalAddressValidator(this.form);

    if (this.zip_code.val()) {
      this.cityUpdaters.zip_code.clearLastSelectedZipCode();
      $('form').formValidation('revalidateField', 'user[zip_code]');
    }

    if (this.zip_code_previous.val()) {
      this.cityUpdaters.zip_code_previous.clearLastSelectedZipCode();
      $('form').formValidation('revalidateField', 'user[zip_code_previous]');
    }

    for (let i = 0; i < Hillary.liablePeopleCount; i += 1) {
      new ZipCodeValidator(this.form, `user[liable_people_attributes][${i}][zip_code]`, () => ({
        zip_code: $(`#user_liable_people_attributes_${i}_zip_code`).val(),
        country_id: $(`#user_liable_people_attributes_${i}_country_id`).find(':selected').val(),
      }));

      $(`#user_liable_people_attributes_${i}_country_id`).on('change', (e) => {
        this.updateMaxZipLengthByCountry(
          `user[liable_people_attributes][${i}][zip_code]`,
          `#user_liable_people_attributes_${i}_country_id`,
        );
        this.cityUpdaters[`liable_person_${i}_zip_code`].clearCurrentInput();
      });

      new FullNameValidator(
        `user[liable_people_attributes][${i}][first_name]`, `user[liable_people_attributes][${i}][last_name]`,
        `user[liable_people_attributes][${i}][full_name]`,
      );
      new AddressValidator(
        `user[liable_people_attributes][${i}][address]`, `user[liable_people_attributes][${i}][street_number]`,
        `user[liable_people_attributes][${i}][full_address]`,
      );

      if ($(`[name="user[liable_people_attributes][${i}][zip_code]"]`).val()) {
        this.cityUpdaters[`liable_person_${i}_zip_code`].clearLastSelectedZipCode();
        $('form').formValidation('revalidateField', `user[liable_people_attributes][${i}][zip_code]`);
      }
    }

    this.enableIbanValidator();
  }

  fetchFormFields() {
    this.form = $('form');

    this.zip_code = $('[name="user[zip_code]"]');
    this.city = $('[name="user[city]"]');
    this.selected_city = $('[name="user[selected_city]"]');

    this.zip_code_previous = $('[name="user[zip_code_previous]"]');
    this.city_previous_autocomplete = $('[data-city-previous="autocomplete"]');
    this.city_previous_free = $('[data-city-previous="free"]');
    this.city_previous = this.city_previous_autocomplete.find('[name="user[city_previous]"]');
    this.cityUpdaters = {
      zip_code: new CityUpdaterMerchant(this.zip_code, this.city, this.selected_city),
      zip_code_previous: new CityUpdater(this.zip_code_previous, this.city_previous),
    };
    for (let i = 0; i < Hillary.liablePeopleCount; i += 1) {
      this.cityUpdaters[`liable_person_${i}_zip_code`] = new CityUpdater(
        $(`[name="user[liable_people_attributes][${i}][zip_code]"]`),
        $(`[name="user[liable_people_attributes][${i}][city]"]`),
      );
    }
  }

  updateCity(data) {
    Object.keys(this.cityUpdaters).forEach((cityUpdaterKey) => {
      this.cityUpdaters[cityUpdaterKey].updateCityFromZipCode(data);
    });
  }

  updateMaxZipLengthByCountry(zipCodeField, countryField) {
    const newLength = $(countryField).find(':selected').attr('data-zip-length');
    this.updateMaxZipLength(zipCodeField, newLength);
  }

  updateMaxZipLength(zipCodeField, newLength) {
    $(`input[name="${zipCodeField}"]`).attr('maxlength', newLength);
    $(`input[name="${zipCodeField}"]`).attr('data-fv-regexp-regexp', `^\\d{${newLength}}`);
    $('form').formValidation('updateOption', zipCodeField, 'stringLength', 'max', newLength);
  }

  updatePermitRequired() {
    if ($('#user_country_id').find(':selected').text() === Hillary.switzerland) {
      $('form').formValidation('enableFieldValidators', 'user[permit_type]', true);
      $('form').formValidation('revalidateField', 'user[permit_type]');
    } else {
      $('label[for=user_permit_type] abbr').hide();
      $('form').formValidation('enableFieldValidators', 'user[permit_type]', false);
    }
    this.permitSelector.checkNationality();
  }

  togglePreviousCityAutocompletion(show) {
    this.city_previous_autocomplete.toggle(show);
    this.city_previous_free.toggle(!show);
  }

  updatePreviousNationality(clearZipCode) {
    if (clearZipCode) {
      $('form').formValidation('resetField', 'user[zip_code_previous]', true);
      $('form').formValidation('resetField', 'user[city_previous]', true);
    }
    this.cityUpdaters.zip_code_previous.updateCityFields([]);
    const nationalityId = $('#user_nationality_previous_id').val();
    const nationality = Hillary.nationalities.filter(el => el.id === parseInt(nationalityId, 10))[0];
    if (nationality != null) {
      this.updateMaxZipLength('user[zip_code_previous]', nationality.zip_length);
      this.togglePreviousCityAutocompletion(true);
    } else {
      this.updateMaxZipLength('user[zip_code_previous]', 20);
      this.togglePreviousCityAutocompletion(false);
    }
  }

  enableIbanValidator() {
    const setIbanValidatorEnabled = () => {
      const enable = $('#user_payment_method_bank_account').is(':checked');
      $('form').formValidation('resetField', 'user[payment_account]');
      $('form').formValidation('enableFieldValidators', 'user[payment_account]', enable, 'mastercardIban');
    };

    $('input[name="user[payment_method]"]').on('change', () => {
      setIbanValidatorEnabled();
    });
    setIbanValidatorEnabled();
  }

  clearServerErrors(data) {
    $(data.element).nextAll('[data-fv-validator="server"]').remove();
  }
}
