export default class MobileNumberTrimmer {
  constructor() {
    // because of users that have IE11 , no arrow function
    const self = this;
    $(document).ready(() => {
      $("[name*='mobile_number']").bindFirst('blur', function () {
        self.trimElementInput($(this));
      });

      $("[name*='phone_number']").bindFirst('blur', function () {
        self.trimElementInput($(this));
      });
    });
  }

  trimElementInput(inputField) {
    $(inputField).val($.trim($(inputField).val()));
  }
}

// enables priority binding for an element
$.fn.bindFirst = function (name, fn) {
  this.on(name, fn);

  this.each(function () {
    const handlers = $._data(this, 'events')[name.split('.')[0]];
    const handler = handlers.pop();
    handlers.splice(0, 0, handler);
  });
};
