export default class CityUpdater {
  constructor(zipCodeField, cityField, selectedCityField = undefined) {
    this.lastSelectedZipCode = null;
    this.zipCodeField = zipCodeField;
    this.cityField = cityField;
    this.cityPrompt = this.cityField.html();
    this.selectedCityField = selectedCityField;
  }

  updateCityFromZipCode(data) {
    if ((data.field === this.zipCodeField.attr('name')) && (data.validator === 'remote')) {
      const selectedZipCode = this.zipCodeField.val();
      if (selectedZipCode !== this.lastSelectedZipCode) {
        this.lastSelectedZipCode = selectedZipCode;
        this.updateCityFields(data.result.cities);
        this.updateTriggered(data);
      }
    }
  }

  updateTriggered(data) {
    // noop
  }

  updateCityFields(cities) {
    this.cityField.html(this.cityPrompt);
    if (cities) {
      $.each(cities, (key, value) => this.cityField.append($('<option></option>').attr('value', value).text(value)));
      if (cities.length === 1) {
        this.cityField.find('option').last().prop('selected', true);
      } else if (this.selectedCityField) {
        const selectedOption = this.cityField.find(`option[value="${this.selectedCityField.val()}"]`);
        if (selectedOption) {
          selectedOption.last().prop('selected', true);
        }
      }
    }
    if (this.cityField.is(':focus')) {
      this.cityField.blur();
      this.cityField.focus();
    }
    this.cityField.change();
  }

  clearLastSelectedZipCode() {
    this.lastSelectedZipCode = null;
  }

  clearCurrentInput() {
    this.cityField.val('');
    this.zipCodeField.val('');
    this.clearLastSelectedZipCode();
  }
}
