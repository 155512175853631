export default class ZipCodeValidator {
  constructor(form, zipCodeField, userDataCallback) {
    form.formValidation('addField', zipCodeField, {
      trigger: 'change',
      verbose: false,
      validators: {
        remote: {
          message: Hillary.invalidZipCodeMessage,
          url: '/zip_codes/validate',
          type: 'GET',
          delay: 200,
          async: true,
          data() {
            return {
              locale: Hillary.locale,
              user: userDataCallback(),
            };
          },
        },
      },
    });
  }
}
