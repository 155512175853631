export default class ReadonlyForm {
  static start() {
    $(document).ready(() => {
      $('[data-readonly-form]').each((_i, element) => {
        const $element = $(element);
        if ($element.data('readonly-form') === true) {
          ['input', 'select'].forEach((formElement) => {
            $element.find(formElement).prop('disabled', true);
          });
        }
      });
    });
  }
}
