import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap-datepicker';
import Rails from '@rails/ujs';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '../../vendor/javascripts/codepage850';
import '../../vendor/javascripts/typeahead';
import '../../src/javascripts/form_validation';
import '../../src/javascripts/common';
import '../../src/javascripts/public';
import '../../images/migrol';
import '../../src/javascripts/common/center_scrollbar';
import '../../src/javascripts/registrations/card_registrations';
import '../../src/javascripts/migrol/controllers/invoice_notification_editing_controller';
import '../../src/javascripts/cookies_checker';

import MigrolDropdown from '../../src/javascripts/migrol/migrol_dropdown';
import Autofocusable from '../../src/javascripts/common/autofocusable';
import HorizontalScrollable from '../../src/javascripts/public/horizontal_scrollable';
import MobileScanPoller from '../../src/javascripts/registrations/mobile_scan_poller';


window.$ = $;
window.Rails = Rails;
Rails.start();
MigrolDropdown.start();
Autofocusable.start();
HorizontalScrollable.start();
MobileScanPoller.start();

// Load Stimulus
const application = Application.start();
const migrolControllers = require.context('../../src/javascripts/migrol/controllers', true, /\.js$/);
const registrationControllers = require.context('../../src/javascripts/registrations/controllers', true, /\.js$/);
application.load(definitionsFromContext(migrolControllers));
application.load(definitionsFromContext(registrationControllers));
