export default class PaymentMethodSwitcher {
  constructor() {
    this.attribute = 'data-payment-method';
    this.methods = $(`[${this.attribute}]`);
    this.enableTarget(this.methods.filter(':checked'));

    this.methods.on('change', (event) => {
      this.enableTarget(event.target);
    });
  }

  enableTarget(input) {
    this.disableAllTargets();
    this.setTargetDisabled(input, false);
  }

  setTargetDisabled(input, disabled) {
    const element = $(input);
    const target = element.attr(this.attribute);
    $(`[data-${target}]`).attr('disabled', disabled);
  }

  disableAllTargets() {
    this.methods.each((_i, input) => {
      this.setTargetDisabled(input, true);
    });
  }
}
