(function ($) {
  FormValidation.Validator.codepage850 = {
    validate(validator, $field, options) {
      const value = $field.val();
      let valid = true;
      valid = !value.split('').some((el, idx, array) => !cptable[850].enc[el]);

      return {
        valid,
        message: Hillary.invalidCodepage850Message,
      };
    },
  };
}(window.jQuery));
