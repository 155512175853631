import LabelsConverter from '../common/labels_converter';

export default class LiablePersonSwitcher {
  constructor() {
    this.labelsConverter = new LabelsConverter();
    this.liablePersonSwitcher = $('[name="user[liable_person_type]"]');
    this.people = $('[data-liable-person]');
    this.addOtherPersonButton = $('[data-add-liable-person]');
    this.liablePersonDetails = $('[data-liable-person-details]');
    this.removeLiablePersonButton = $('[data-remove-liable-person]');

    this.switchLiablePersonDetailsBlock(true);
    this.liablePersonSwitcher.on('change', () => this.switchLiablePersonDetailsBlock(false));
    this.addOtherPersonButton.on('click', () => this.addOtherPerson());
    this.removeLiablePersonButton.on('click', () => this.removeOtherPerson());
    this.updateRemoveButtons(this.getVisiblePeople().last());
  }

  switchLiablePersonDetailsBlock(initial) {
    if (!this.liablePersonSwitcher.length) {
      return;
    }
    const visible = ['other', 'both'].indexOf(this.liablePersonSwitcher.filter(':checked').val()) !== -1;
    this.liablePersonDetails.toggle(visible);
    if (!initial && !visible) {
      $('form').data('formValidation').$submitButton = null; // TODO: Hack to not submit the form when calling validate
      $('form').formValidation('validate');
    }
    this.updateAddButton();
  }

  addOtherPerson() {
    const first = this.people.filter('.d-none').first();
    first.removeClass('d-none');
    first.find('[data-destroy-liable-person]').val('false');
    this.updateRemoveButtons(first);
    this.updateAddButton();
  }

  updateRemoveButtons(lastVisiblePerson) {
    this.removeLiablePersonButton.addClass('d-none');
    if (lastVisiblePerson) {
      lastVisiblePerson.find('[data-remove-liable-person]').removeClass('d-none');
      this.updateAddButton();
    }
  }

  updateAddButton() {
    const visible = this.canAddMorePeople();
    this.addOtherPersonButton.toggle(visible);
    return visible;
  }

  removeOtherPerson() {
    const visiblePeople = this.getVisiblePeople();
    if (visiblePeople.length === 1) {
      return;
    }
    this.resetInputValues(visiblePeople.last());
    visiblePeople.last().addClass('d-none');
    visiblePeople.last().find('[data-destroy-liable-person]').val('true');
    this.updateRemoveButtons(this.getVisiblePeople().last());
  }

  resetInputValues(person) {
    person.find('input[type="text"],select').each((idx, field) => {
      field = $(field);
      field.val('');
      $('form').formValidation('resetField', field.attr('name'));
      this.labelsConverter.switchLabel(field);
    });
    person.find('input[type="checkbox"]:checked').click();
    const countrySelect = person.find('select[name$="[country_id]"][name^="user[liable_people_attributes]"]');
    countrySelect.val(countrySelect.find('option:first').val());
    this.labelsConverter.switchLabel(countrySelect);
  }

  canAddMorePeople() {
    return this.people.filter('.d-none').length > 0;
  }

  getVisiblePeople() {
    return this.people.filter(':not(.d-none)');
  }
}
