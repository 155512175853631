export default class SecondCardSelector {
  constructor() {
    this.selector = $('input[type=checkbox][name="user[order_second_card]"]');
    this.secondCardBlock = $('[data-second-card-block]');
    this.selector.on('click', () => this.changeVisibility());
  }

  changeVisibility() {
    this.secondCardBlock.toggle(this.selector[0].checked);
  }
}
