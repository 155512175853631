export default class PromotionFields {
  constructor() {
    this.selector = $('[data-promocode]');
    this.lastSubmittedValue = this.selector.val();
    this.selector.on('change', e => this.updatePromotionFields(e.currentTarget));
    this.selector.on('keydown', (e) => {
      const key = e.which || e.keyCode;
      if (key === 13 && this.lastSubmittedValue !== $(e.currentTarget).val()) {
        e.preventDefault();
        this.updatePromotionFields(e.currentTarget);
      }
    });
  }

  updatePromotionFields(promocodeInput) {
    const input = $(promocodeInput);
    this._setSubmitButtonDisabled(input, true);
    let url = `${input.data('promotionUrl')}&promocode=${input.val()}`;
    if (input.data('userId')) {
      url += `&user_id=${input.data('userId')}`;
    }
    this.lastSubmittedValue = input.val();
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'script',
      success: () => this._setSubmitButtonDisabled(input, false),
      error: () => this._setSubmitButtonDisabled(input, false),
    });
  }

  _setSubmitButtonDisabled(element, enabled) {
    element.parents('form').find('input[type="submit"]').prop('disabled', enabled);
  }
}
