import TwoFactorCountdown from './two_factor_countdown';

window.HillaryTwoFactorConfirmationsNew = class HillaryTwoFactorConfirmationsNew {
  constructor() {
    new TwoFactorCountdown().start();
    this.setupFormValidation();
  }

  setupFormValidation() {
    this.form = $('form');
    return this.form.formValidation({
      locale: Hillary.shortLocale,
      trigger: 'keyup',
      fields: {
        'user[verification_code]': {
          message: Hillary.invalidVerificationCode,
        },
      },
    });
  }
};
