const POLL_INTERVAL = 5000;

export default class MobileScanPoller {
  static start() {
    $(document).ready(() => new MobileScanPoller().setup());
  }

  setup() {
    $('[data-mobile-identity-scan]').each((_idx, element) => {
      const token = $(element).data('mobileIdentityScan');
      this.checkResult(token);
      const intervalId = setInterval(() => this.checkResult(token, intervalId), POLL_INTERVAL);
    });
  }

  checkResult(token, intervalId) {
    $.ajax(`/mobile_scans/${token}/result`).done((result) => {
      if (result.uploaded) {
        if (intervalId) {
          clearInterval(intervalId);
        }
        window.document.location = result.redirect_url;
      }
    }).fail(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    });
  }
}
