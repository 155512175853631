import '../public/codepage850';

export default class CardOperationValidator {
  constructor() {
    $('form').formValidation({
      locale: Hillary.shortLocale,
      trigger: 'blur',
      fields: {
        'card_operation[embossing_line_1]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
        'card_operation[embossing_line_2]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
        'card_operation[cost_center]': {
          validators: {
            codepage850: {
              enabled: Hillary.shouldValidateCodepage850,
            },
          },
        },
      },
    });
  }
}
