export default class PreValidationScreen {
  constructor() {
    this.overlay = $('[data-overlay]');
    if (this.overlay.length > 0) {
      this.hideClass = 'd-none';
      this.questionsIndexes = [1, 2, 3];
      this.mainFormContainer = $('[data-main-form]');
      this.nextButton = $('[data-pre-validation="finish"]');
      this._hide(this.mainFormContainer);
      this._show(this.overlay);

      $('[data-answer]').on('change', () => {
        this.showErrors();
        if (this._allAnswersTrue()) {
          this._show(this.nextButton);
        } else {
          this._hide(this.nextButton);
        }
      });

      this.nextButton.on('click', () => {
        this._hide(this.overlay);
        this._show(this.mainFormContainer);
        return false;
      });
    }
  }

  showErrors() {
    this.questionsIndexes
      .forEach((_, i) => {
        const val = $(`[name='question_${i + 1}']:checked`).val();
        if (val === 'false') {
          this._show($(`[data-question-help=${i + 1}]`));
        } else {
          this._hide($(`[data-question-help=${i + 1}]`));
        }
      });
  }

  _allAnswersTrue() {
    return this.questionsIndexes
      .map((_, i) => $(`[name='question_${i + 1}']:checked`).val())
      .every(answer => answer === 'true');
  }

  _hide(element) {
    element.addClass(this.hideClass);
  }

  _show(element) {
    element.removeClass(this.hideClass);
  }
}
