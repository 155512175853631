export default class AddressValidator {
  constructor(addressField, streetNumberField, fullAddressField) {
    this.updateFullAddress = this.updateFullAddress.bind(this);
    this.address = $(`[name="${addressField}"]`);
    this.streetNumber = $(`[name="${streetNumberField}"]`);
    this.fullAddressField = fullAddressField;
    this.fullAddress = $(`[name="${fullAddressField}"]`);
    this.address.on('change', e => this.updateFullAddress());
    this.streetNumber.on('change', e => this.updateFullAddress());
    $('#user_country_id').on('change', e => this.updateInSwitzerlandSince());
  }

  updateFullAddress() {
    this.fullAddress.val(`${this.address.val()} ${this.streetNumber.val()}`);
    $('form').formValidation('revalidateField', this.fullAddressField);
  }

  updateInSwitzerlandSince() {
    if ($('[name="user[nationality_type]"]').filter(':checked').val() !== 'swiss') {
      if ($('#user_country_id').find(':selected').text() === Hillary.switzerland) {
        $('[data-field="in-switzerland-since"]').show();
      } else {
        $('[data-field="in-switzerland-since"]').hide();
      }
    }
  }
}
