import LabelsConverter from './labels_converter';

class PageInitializer {
  constructor() {
    const page = `Hillary${$('body').data('page')}`;
    this.executePageJs(page);
  }

  executePageJs(page) {
    if (typeof window[page] === 'function') {
      const Klass = window[page];
      new Klass();
    }
  }
}


$(document).ready(() => {
  new LabelsConverter().attach();
  new PageInitializer();
});
