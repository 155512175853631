import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['number'];
  }

  formatNumber() {
    let cursorPosition = this.numberTarget.selectionEnd;
    const initialNumber = this.numberTarget.value.toString();
    const spacing = this.data.get('spacing');
    const maxLength = this.data.get('max-length');

    const regex = new RegExp(`\\d{${spacing}}(?=.)`, 'g');
    let formattedNumber = initialNumber.replaceAll(/[^\d]/g, '').replace(regex, '$& ');

    if (maxLength && formattedNumber.length > maxLength) {
      formattedNumber = formattedNumber.substring(0, maxLength);
    }

    if (initialNumber === formattedNumber) { return; }

    this.numberTarget.value = formattedNumber;
    cursorPosition += formattedNumber.length - initialNumber.length;
    this.numberTarget.setSelectionRange(cursorPosition, cursorPosition);
  }
}
