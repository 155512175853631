export default class InvitationLetterValidator {
  constructor() {
    $('form').formValidation({
      locale: Hillary.shortLocale,
      trigger: 'blur',
      fields: {
        'invitation_letter_request[birthdate]': {
          validators: {
            date: Hillary.initialBirthdateOptions,
          },
        },
        'invitation_letter_request[card_or_account_number]': {
          validators: {
            stringLength: {
              max: Hillary.cardOrAccountLength,
            },
          },
        },
      },
    });
  }
}

