export default class MobileDropDown {
  static start() {
    $(document).ready(() => {
      const MOBILE_PHONE_WIDTH = 576;
      if ($(window).width() <= MOBILE_PHONE_WIDTH) {
        $(document).on('click', '.dropdown-menu.multi-level', (e) => {
          e.stopPropagation();
        });
      }
    });
  }
}
