export default class HorizontalScrollable {
  static start() {
    $(document).ready(() => new HorizontalScrollable().start());
  }

  get horizontalScrollableContainers() {
    return $('[data-horizontal-scroll]');
  }

  start() {
    window.addEventListener('resize', this._layout.bind(this));
    this._layout();
    this._setupArrowButtons();
    this._setupScrollListener();
  }

  _setupArrowButtons() {
    $('[data-horizontal-scroll] .left-button').on('click', e => this._scroll(e.target, -100));
    $('[data-horizontal-scroll] .right-button').on('click', e => this._scroll(e.target, 100));

    this.horizontalScrollableContainers.each((_i, horizontalScrollableContainer) => {
      const $horizontalScrollableContainer = $(horizontalScrollableContainer);

      this._reevaluateButtons(
        $horizontalScrollableContainer,
        $horizontalScrollableContainer.children('.scrolling-container'),
      );
    });
  }

  _setupScrollListener() {
    this.horizontalScrollableContainers.children('.scrolling-container').on('scroll', (e) => {
      const $scrollingContainer = $(e.target);
      this._reevaluateButtons($scrollingContainer.parent(), $scrollingContainer);
    });
  }

  _scroll(button, amount) {
    const $horizontalScrollableContainer = $(button).parent();
    const $scrollingContainer = $horizontalScrollableContainer.children('.scrolling-container');

    $scrollingContainer.scrollLeft($scrollingContainer.scrollLeft() + amount);
    this._reevaluateButtons($horizontalScrollableContainer, $scrollingContainer);
  }

  _reevaluateButtons($horizontalScrollableContainer, $scrollingContainer) {
    const { parentWidth, contentWidth } = this._measureScrollingContainer($scrollingContainer);
    const scrollLeft = $scrollingContainer.scrollLeft();

    $horizontalScrollableContainer.toggleClass('rightmost', parentWidth + scrollLeft >= contentWidth);
    $horizontalScrollableContainer.toggleClass('leftmost', scrollLeft <= 0);
  }

  _layout() {
    this.horizontalScrollableContainers.each((_i, element) => {
      this._layoutContainer($(element));
    });
  }

  _layoutContainer($horizontalScrollableContainer) {
    const $scrollingContainer = $horizontalScrollableContainer.children('.scrolling-container');
    const { parentWidth, contentWidth } = this._measureScrollingContainer($scrollingContainer);

    if (parentWidth < contentWidth) {
      this._activateScrolling($horizontalScrollableContainer);
    } else {
      this._deactivateScrolling($horizontalScrollableContainer);
    }
  }

  _measureScrollingContainer($scrollingContainer) {
    const parentWidth = $scrollingContainer.width();
    const contentWidth = $scrollingContainer.children('.scroll-content').width();

    return {
      parentWidth,
      contentWidth,
    };
  }

  _activateScrolling($horizontalScrollableContainer) {
    if (!$horizontalScrollableContainer.hasClass('not-scrollable')) {
      return;
    }

    $horizontalScrollableContainer.addClass('scrollable');
    $horizontalScrollableContainer.removeClass('not-scrollable');
  }

  _deactivateScrolling($horizontalScrollableContainer) {
    if ($horizontalScrollableContainer.hasClass('not-scrollable')) {
      return;
    }

    $horizontalScrollableContainer.addClass('not-scrollable');
    $horizontalScrollableContainer.removeClass('scrollable');
  }
}
