import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['line1', 'line2', 'line3'];
  }

  line1Changed(e) {
    this._lineChanged(this.line1Target, e);
  }

  line2Changed(e) {
    this._lineChanged(this.line2Target, e);
  }

  line3Changed(e) {
    this._lineChanged(this.line3Target, e);
  }

  _lineChanged(target, event) {
    $(target).html(event.target.value);
  }
}
