import CityUpdater from './city_updater';

export default class CityUpdaterMerchant extends CityUpdater {
  constructor(zipCodeField, cityField, selectedCityField = undefined) {
    super(zipCodeField, cityField, selectedCityField);
  }

  updateTriggered(data) {
    /* eslint-disable camelcase */
    const { merchant_id } = data.result;
    $('[name="user[favourite_merchant_id]"]').val(merchant_id).trigger('change');
    /* eslint-enable camelcase */
  }
}
