import PaymentMethodSwitcher from './payment_method_switcher';

class CardRegistrations {
  constructor() {
    new PaymentMethodSwitcher();
  }
}

$(document).ready(() => {
  new CardRegistrations();
});
