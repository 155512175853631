import $ from 'jquery';
import Rails from '@rails/ujs';
import 'regenerator-runtime';

export default class RemoteDownload {
  static start() {
    $(document).ready(() => {
      $('[data-remote-download]').each((_i, element) => {
        const $element = $(element);
        const filePath = $element.attr('href');
        $element.on('click', async (event) => {
          event.preventDefault();
          event.stopPropagation();

          try {
            this._disableButtonElement($element);
            await this._download(filePath);
          } finally {
            this._enableButtonElement($element);
          }
        });
      });
    });
  }

  static _disableButtonElement($button) {
    $button.attr('disabled', true);
    $button.toggleClass('disabled', true);
  }

  static _enableButtonElement($button) {
    $button.attr('disabled', false);
    $button.toggleClass('disabled', false);
  }

  static _download(url) {
    return new Promise(((resolve, reject) => {
      Rails.ajax({
        url,
        async: true,
        type: 'GET',
        data: '',
        beforeSend: (xhr) => {
          xhr.responseType = ('ArrayBuffer' in window) ? 'arraybuffer' : 'text';
          return true;
        },
        success: (data) => {
          const blobby = this._convertToBlob(data);
          this._openBlob(blobby);
          resolve();
        },
        error: reject,
      });
    }));
  }

  static _convertToBlob(data) {
    if ('Blob' in window) {
      return new Blob([data], { type: 'text/csv;charset=utf-8' });
    }

    return this._buildLegacyBlob(data);
  }

  static _buildLegacyBlob(data) {
    const BlobBuilder = window.BlobBuilder || window.MSBlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder;

    const blobBuilder = new BlobBuilder();
    blobBuilder.append(data);

    return blobBuilder.getBlob('text/csv');
  }

  static _openBlob(blob, fileName = 'export.csv') {
    if ('msSaveBlob' in navigator) {
      return navigator.msSaveBlob(blob, fileName);
    }

    const anchorElement = document.createElement('a');
    anchorElement.href = window.URL.createObjectURL(blob);
    anchorElement.download = fileName;
    document.body.appendChild(anchorElement);
    anchorElement.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    anchorElement.remove();
    window.URL.revokeObjectURL(anchorElement.href);

    return true;
  }
}
