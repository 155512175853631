import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['accepted', 'details'];
  }

  toggleDetails(_event) {
    this.detailsTarget.classList.toggle('d-none');
  }
}
