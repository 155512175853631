import ValidationSwitcher from './validation_switcher';

export default class VisibilitySwitcher {
  constructor(switcher, switched, inverted) {
    this.switchVisibility = this.switchVisibility.bind(this);
    this.switcher = switcher;
    this.switched = switched;
    this.inverted = inverted || false;
    this.switchVisibility(true);
    this.switcher.on('click', () => this.switchVisibility());
  }

  switchVisibility(initial) {
    let checked = this.switcher.is(':checked');
    if (this.inverted) {
      checked = !checked;
    }
    if (!initial) {
      new ValidationSwitcher(this.switched, !checked);
    }
    this.switched.toggle(!checked);
  }
}
