const COLLAPSED_SESSION_STORAGE_KEY = 'hillary.portal.graph_collapsible.collapsed';

export default class GraphCollapsible {
  static start() {
    $(document).ready(() => {
      $('[data-graph-collapsible]').each((_i, element) => new GraphCollapsible($(element)).setup());
    });
  }

  constructor($element) {
    this.$element = $element;
    this.$collapsible = $(this.$element.attr('href') || this.$element.attr('data-target'));
  }

  setup() {
    this.$collapsible.on('show.bs.collapse', () => this._collapsibleGotShown());
    this.$collapsible.on('hide.bs.collapse', () => this._collapsibleGotHidden());
    this._recoverState();
  }

  _recoverState() {
    if (this._getSessionStorageBoolean(COLLAPSED_SESSION_STORAGE_KEY)) {
      this._forceCollapse();
    }
  }

  _forceCollapse() {
    this.$collapsible.addClass('hide collapse').removeClass('show');
    this._collapsibleGotHidden();
  }

  _collapsibleGotHidden() {
    this.$element.children('[data-shown]').toggleClass('d-none', true);
    this.$element.children('[data-hidden]').toggleClass('d-none', false);
    this._setSessionStorageBoolean(COLLAPSED_SESSION_STORAGE_KEY, true);
  }

  _collapsibleGotShown() {
    this.$element.children('[data-shown]').toggleClass('d-none', false);
    this.$element.children('[data-hidden]').toggleClass('d-none', true);
    this._setSessionStorageBoolean(COLLAPSED_SESSION_STORAGE_KEY, false);
  }

  _getSessionStorageBoolean(key, defaultValue = false) {
    if ('sessionStorage' in window) {
      return sessionStorage.getItem(key) === 'true';
    }

    return defaultValue;
  }

  _setSessionStorageBoolean(key, value) {
    if ('sessionStorage' in window) {
      sessionStorage.setItem(key, value.toString());
    }
  }
}
