export default class HillaryPostValidationsNew {
  constructor() {
    this.questionsIndexes = [1, 2, 3];
    this.updateButtonState();
    $('[data-answer]').on('change', () => {
      $('#user_post_validated').val(this.isAllValid());
      this.updateButtonState();
    });
  }

  isAllValid() {
    const expectedAnswers = { 0: 'false', 1: 'true', 2: 'true' };
    return this.questionsIndexes.map(index => $(`[name='question_${index}']:checked`).val())
      .every((val, index) => val === expectedAnswers[index]);
  }

  updateButtonState() {
    const changesMade = $('[name="question_1"]:checked');
    $('[data-post-validation-user-edit]').toggle(changesMade.val() === 'true');
    $('input[type="submit"]').prop('disabled', !this.isAllValid());
  }
}

window.HillaryPostValidationsNew = HillaryPostValidationsNew;
