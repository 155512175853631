export default class FullNameValidator {
  constructor(firstNameField, lastNameField, fullNameField) {
    this.updateFullName = this.updateFullName.bind(this);
    this.firstName = $(`[name="${firstNameField}"]`);
    this.lastName = $(`[name="${lastNameField}"]`);
    this.fullNameField = fullNameField;
    this.fullName = $(`[name="${fullNameField}"]`);
    this.firstName.on('change', (e) => {
      this.normalizeValue(this.firstName);
      this.updateFullName();
    });
    this.lastName.on('change', (e) => {
      this.normalizeValue(this.lastName);
      this.updateFullName();
    });
  }

  updateFullName() {
    this.fullName.val(`${this.lastName.val()} ${this.firstName.val()}`);
    return $('form').formValidation('revalidateField', this.fullNameField);
  }

  normalizeValue(field) {
    const originalValue = field.val();
    const normalized = originalValue.trim().toLowerCase().replace(/(^|\s|-)\S/g, m => m.toUpperCase());
    return field.val(normalized);
  }
}
